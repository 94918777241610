'use client';

import { useState } from 'react';

import { QueryClient, QueryClientProvider, dehydrate } from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClientConfig } from '@/lib/queryClient';

// export async function getServerSideProps() {
//   const queryClient = new QueryClient();

//   // Prefetch the data on the server
//   await queryClient.prefetchQuery(getUsersKey(), getUsers);

//   return {
//     props: {
//       dehydratedState: dehydrate(queryClient),
//     },
//   };
// }

const Providers = ({ children }: React.PropsWithChildren) => {
  const [queryClient] = useState(() => new QueryClient(queryClientConfig));

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default Providers;
